import "./styles.scss";
import sonographe from "../../assets/sonographe.gif";
import React from "react";

class SyncMusic extends React.Component<any, any> {

    intervalID : any;
    clock : number = 1;

    constructor(props: any) {
        super(props);
        this.state = {
            bpm: 126,
            bullet_active: true,
            phase: true,
            beat2: false,
            beat3: false,
            beat4: false,
        };
    }
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000/this.state.bpm
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    tick() {
        this.clock++;
        if(this.clock == 5)
            this.clock = 1;

        this.setState({
            bullet_active: (this.clock == 1 || this.clock == 3) ? true : false,
            phase: this.clock == 1 ? true : false,
            beat2: this.clock == 2 ? true : false,
            beat3: this.clock == 3 ? true : false,
            beat4: this.clock == 4 ? true : false
        })
    }
    render() {
        return (
            <section className="SyncMusic-container">
                <section className="SyncMusic-container_center">
                    <div className="SyncMusic-container_center_left">
                    </div>
                    <div className="SyncMusic-container_center_right">
                        <h1 className="title">Sync it with music</h1>
                        <div className="SyncMusic-container_center_right_illu">
                            <div className="SyncMusic-container_center_right_illu_compteur">
                                <div className="SyncMusic-container_center_right_illu_compteur_left">
                                    <div
                                        className={`SyncMusic-container_center_right_illu_compteur_left_bulle ${this.state.bullet_active ? "active" : ""}`}></div>
                                </div>
                                <div className="SyncMusic-container_center_right_illu_compteur_right">
                                    <div className="SyncMusic-container_center_right_illu_compteur_right_bpm">
                                        {this.state.bpm}
                                        <div
                                            className="SyncMusic-container_center_right_illu_compteur_right_bpm_legend">BPM
                                        </div>
                                    </div>
                                    <div className="SyncMusic-container_center_right_illu_compteur_right_phases">
                                        <div
                                            className={`SyncMusic-container_center_right_illu_compteur_right_phases_phase ${this.state.phase ? "active" : ""}`}></div>
                                        <div
                                            className={`SyncMusic-container_center_right_illu_compteur_right_phases_beat ${this.state.beat2 ? "active" : ""}`}></div>
                                        <div
                                            className={`SyncMusic-container_center_right_illu_compteur_right_phases_beat ${this.state.beat3 ? "active" : ""}`}></div>
                                        <div
                                            className={`SyncMusic-container_center_right_illu_compteur_right_phases_beat ${this.state.beat4 ? "active" : ""}`}></div>
                                    </div>
                                </div>
                            </div>
                            <img src={sonographe}/>
                        </div>
                        <p className="text">
                            All sequences/transitions can be synchronized with the music.
                            We created a new beat detection algorithm exclusively for Photon.
                            Play live music and it will automatically find the beat and adapt lights animations on
                            the rhythm.
                        </p>
                        <ul className="list">
                            <li className="list_item">
                                <div className="icon">
                                    <i className="bi bi-bullseye"></i>
                                </div>
                                <p>Tap tempo</p>
                            </li>
                            <li className="list_item">
                                <div className="icon">
                                    <i className="bi bi-mic-fill"></i>
                                </div>
                                <p>Microphone</p>
                            </li>
                            <li className="list_item">
                                <div className="icon">
                                    <i className="bi bi-upc"></i>
                                </div>
                                <p>Ableton Link</p>
                            </li>

                        </ul>
                    </div>
                </section>
            </section>
        );
    }
}
 
export default SyncMusic;