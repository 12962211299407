import React, {Fragment} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import './App.scss';
import Community from './components/Community/Community';
import ControlLights from './components/ControlLights/ControlLights';
import CustomizeDashboard from './components/CustomizeDashboard/CustomizeDashboard';
import Footer from './components/Footer/Footer';
import Hardware from './components/Hardware/Hardware';
import Header from './components/Header/Header';
import Info from './components/Info/Info';
import Landing from './components/Landing/Landing';
import SyncMusic from './components/SyncMusic/SyncMusic';
import Buy from './components/Buy/Buy';
import ContactForm from './components/ContactForm/ContactForm';
import Privacy from './components/Privacy/Privacy';
import SmartLights from './components/SmartLights/SmartLights';
import FixtureLibrary from './components/FixtureLibrary/FixtureLibrary';
import MIDI from './components/MIDI/MIDI';

import 'react-simple-hook-modal/dist/styles.css';

import {
    ModalProvider
} from 'react-simple-hook-modal';

function App() {
  return (
      <Router>
          <ModalProvider>
              <section className="Main">
                  <Header />
                  <Routes>
                      <Route path="/contact" element={<ContactPage />} />
                      <Route path="/privacy" element={<PrivacyPage />} />
                      <Route path="/" element={<HomePage />} />
                  </Routes>
                  <Footer />
              </section>
          </ModalProvider>
      </Router>
  );
}

function HomePage() {
    return (
        <div>
            <Landing />
            <ControlLights />
            <SyncMusic />
            <CustomizeDashboard />
            <SmartLights />
            <FixtureLibrary />
            <Hardware />
            <MIDI />
            <Community />
            <Buy />
        </div>
    );
}

function ContactPage() {
    return (
        <div>
            <GoogleReCaptchaProvider reCaptchaKey="6LeqdOMZAAAAAHal4IlrUxpDEjhpQdMYaismvVGg">
                <ContactForm />
            </GoogleReCaptchaProvider>
        </div>
    );
}

function PrivacyPage() {
    return (
        <div>
            <Privacy />
        </div>
    );
}

export default App;
