import { BuyProps } from "../../interfaces/Interface";
import "./styles.scss";
import BuyImg from "../../assets/illu_buy.png"
import appleBtnImage from "../../assets/apple_btn.png";
 
const Buy: React.FC<BuyProps> = () => {
    return ( 
        <section className="Buy-container">
            <div className="Buy-container_center">
                <div className="Buy-container_center_img">
                    <img src={BuyImg} alt=""/>
                </div>

                <h1>Buy Photon 2</h1>

                <div className={"Buy-container_center_subscription"} >
                    Subscription
                </div>
                <div className={"Buy-container_center_onetime"} >
                    ONE TIME PAYMENT
                </div>

                <div className={"table"} >
                    <div className={"pack free"} >
                        <div className={"header"} >
                            <h2>Free</h2>
                            <div className={"price"}>0$</div>
                        </div>
                        <div className={"list"} >
                            <div className={"item"} >Fixture factory/library</div>
                            <div className={"item"} >Limited to 16 presets/sequences</div>
                            <div className={"item"} >Limited to 10 widgets on dashboard</div>
                            <div className={"item"} >Manual automation<br /> (fixed BPM)</div>
                            <div className={"item"} >Ableton Link synchronization<br /> (<a href={"https://www.ableton.com/link/"} target="_blank">learn more</a>)</div>
                            <div className={"item"} >First 16 DMX channels</div>
                        </div>
                    </div>
                    <div className={"pack starter"} >
                        <div className={"header"} >
                            <h2>Starter</h2>
                            <div className={"price"}>44.99$</div>
                        </div>
                        <div className={"list"} >
                            <div className={"item_pack"} >
                                <h4>Free plan</h4><br />
                                <div className={"plus"}>+</div>
                            </div>
                            <div className={"item"} >Unlimited presets/sequences</div>
                            <div className={"item"} >Unlimited widgets on dashboard</div>
                            <div className={"item"} >App can run in background</div>
                            <div className={"item"} >Live music synchronization<br /> (built-in mic)</div>
                            <div className={"item"} >First 128 DMX channels</div>
                        </div>
                    </div>
                    <div className={"pack full"} >
                        <div className={"header"} >
                            <h2>Pro</h2>
                            <div className={"price"}>
                                99.99$
                            </div>
                            <div className={"reduction"}>
                                Limited offer<br />
                                79.99$
                            </div>
                        </div>
                        <div className={"list"} >
                            <div className={"item_pack"} >
                                <h4>Starter plan</h4><br />
                                <div className={"plus"}>+</div>
                            </div>
                            <div className={"item"} >MIDI inputs (external triggers)</div>
                            <div className={"item"} >First 512 DMX channels</div>
                        </div>
                    </div>
                </div>
                <a href="https://apps.apple.com/us/app/photon-2-dmx-light-controller/id1545017671" className="Buy-container_center_btnContainer">
                    <div className="btn">
                        <img src={appleBtnImage} alt=""/>
                    </div>
                </a>
            </div>
        </section>
     );
}
 
export default Buy;
