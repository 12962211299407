import { HeaderProps } from "../../interfaces/Interface";
import "./styles.scss";
import logo from "../../assets/logo.png";
import { useRef, useState } from "react";

 
const Header: React.FC<HeaderProps> = () => {

    const menuMobile = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        isOpen ? setIsOpen(false) : setIsOpen(true);
    }

    return ( 
        <header className="Header">
            <div className="Logo">
                <a href={"/"} >
                    <div className="Logo_img" >
                        <img src={logo} alt=""/>
                    </div>
                </a>
                <a href={"/"} >
                    <h1 className="Logo_title">Photon</h1>
                </a>
            </div>
            <nav>
                <ul className="Nav-links">
                    <li><a href="https://docs.photon-ios.com/" title="Photon 2 User Guide - Documentation" className="Nav-links_link">Documentation</a></li>
                    <li><a target="_blank" href="https://community.photon-ios.com/" className="Nav-links_link">Community</a></li>
                    <li><a href="/contact" className="Nav-links_link">Contact</a></li>
                </ul>
            </nav>
            <nav className={`Header_mobile ${isOpen ? "open" : ""}`} ref={menuMobile}>
                <ul className="links">
                    <li><a target="_blank" href="https://community.photon-ios.com/" className="link">Community</a></li>
                    <li><a href="/contact" className="link">Contact</a></li>
                </ul>
            </nav>
            <div className="Header_menu" onClick={handleClick}>
                <div className={`top-bar ${isOpen ? "open" : ""}`}></div>
                <div className={`middle-bar ${isOpen ? "open" : ""}`}></div>
                <div className={`bottom-bar ${isOpen ? "open" : ""}`}></div>
            </div>
        </header>
     );
}
 
export default Header;