import { MIDIProps } from "../../interfaces/Interface";
import "./styles.scss";

import MIDIKeyboard from "../../assets/midi-keyboard.jpg"

import {useRef} from "react";
import {useIntersection} from "use-intersection";
 
const MIDI: React.FC<MIDIProps> = () => {

    const textAnimation = useRef(null);
    const isTextAnimation = useIntersection(textAnimation, {threshold: 1, once: true});
    return (
        <section className="MIDI-container">
            <div className="MIDI-container_center">
                <div className="midi_keyboard">
                    <div className="midi_keyboard_img">
                        <img src={MIDIKeyboard} alt=""/>
                    </div>
                    <div className="midi_keyboard_description">
                        <h1>MIDI Inputs <span>New</span></h1>
                        <p className="text" >
                            External triggers can interact with your fixtures, presets and sequences. Connect external wireless and wired MIDI controllers : keyboards, pads, timecoders or even softwares and other apps. <i>*Pro version only</i>
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default MIDI;