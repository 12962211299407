import { CommunityProps } from "../../interfaces/Interface";
import "./styles.scss";
 
const Community: React.FC<CommunityProps> = () => {
    return ( 
        <section className="Community-container">
            <div className="Community-container_description">
                <h2>
                    Need help ?<br />
                    Have suggestions ?
                </h2>
            </div>
            <a target="_blank" href="https://community.photon-ios.com/" className="Community-container_btn">Visit the Community <i className="bi bi-chevron-right"></i></a>
        </section>
     );
}
 
export default Community;