import "./styles.scss";
import React, {useCallback, useMemo, useState} from "react";
import {PrivacyProps} from "../../interfaces/Interface";

const Privacy: React.FC<PrivacyProps> = () => {

    return (
        <section className="Privacy-container">
            <section className="Privacy-container-center">
                <h1 className="Privacy-container-center_title">Privacy policy</h1>

                <div className="Privacy-container-center_content">
                    <p>Photon ("us", "we", or "our") operates the https://photon-ios.com/ website and the Photon app as a commercial software product application ("app").</p>

                    <p>This page informs you of our policies regarding the collection, use,
                        and disclosure of personal data when you use our app.</p>

                    <p>If you choose to use Photon app, then you agree to the collection and use of information in
                        relation with this policy. The Personal Information that we collect is used for providing and
                        improving Photon app. We will not use or share your information with anyone except as described
                        in this Privacy Policy.</p>

                    <h4>Information Collection and Use</h4>

                    <p>
                        Photon app requires access to your device's Camera and Photo library in order to assign photos to preset, sequence and dashboard button.
                        Photon app uses your device's microphone in order to synchronize content with music.
                        This data requested by Photon app is solely retained on your device for the purpose of enabling specific features and is not collected by us in any way.
                    </p>
                    <p>
                        If you submit a fixture request, report an error on fixture or share fixture, we may receive your email address and other information you provide related to your request.
                        Such addresses and information are not used for any other purpose and are not shared with outside parties.
                    </p>
                    <p>
                        Photon app synchronize your fixtures library with an API develop and owned by Photon.
                        When we synchronize your library, we store your device’s Internet Protocol (“IP”) address and the time of the synchronization.
                    </p>

                    <h4>Log Data</h4>
                    <p>
                        We want to inform you that whenever you use Photon app, in case of an errors within the app that we may collect
                        anonymous data and information about your device called Log Data. This Log Data
                        may include information such as your devices’s Internet Protocol (“IP”) address, device name,
                        operating system version, configuration of the app when utilizing our app, the time and date
                        of your use, and other statistics.
                    </p>


                    <h4>Security</h4>
                    <p>
                        The security of your data is important to us but remember that no method of transmission over
                        the Internet or method of electronic storage is 100% secure.
                        While we strive to use commercially acceptable means to protect your Personal Data,
                        we cannot guarantee its absolute security.
                    </p>

                    <h4>Links to Other Sites</h4>
                    <p>
                        Photon app may contain links to other sites. If you click on a third-party link, you will be
                        directed to that site. Note that these external sites are not operated by us. Therefore, we
                        strongly advise you to review the Privacy Policy of these websites. We have no control over, and
                        assume no responsibility for the content, privacy policies, or practices of any third-party
                        sites or services.
                    </p>

                    <h4>Children’s Privacy</h4>
                    <p>
                        Our Service does not address anyone under the age of 18 ("Children").
                        We do not knowingly collect personally identifiable information from anyone under the age of 18.
                        If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us.
                        If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                    </p>

                    <h4>Changes to This Privacy Policy</h4>
                    <p>
                        We may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically for any changes.
                        We will notify you of any changes by posting the new Privacy Policy on this page.
                        These changes are effective immediately, after they are posted on this page.
                    </p>

                    <h4>Contact Us</h4>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us by visiting this page on our website: <a href={"/contact"} >https://photon-ios.com/contact</a>.
                    </p>
                </div>
            </section>
        </section>
    );
}
 
export default Privacy;