import { SmartLightsProps } from "../../interfaces/Interface";
import "./styles.scss";

import { FaCloud } from "react-icons/fa";

import Hue from "../../assets/hue.png"
import HueLight from "../../assets/hue_light.png"

const SmartLights: React.FC<SmartLightsProps> = () => {
    return (
        <section className="Cloud-container">
            <div className="Cloud-container_center">
                <div className="cloud">
                    <div className="cloud_img">
                        <img className={"hue_logo"} src={Hue}/>
                    </div>
                    <div className="cloud_description">
                        <h1>Smart lights</h1>
                        <p className="text" >
                            You can now control your <b>Philips Hue</b> lights and other DMX lights in the same project.
                            Simply connect your bridge to the app to use all the features of Photon with your Philips Hue*.
                            Brightening up your most beautiful evening at home or controlling strip lights in your bar is now at your fingertips !<br /><br />
                            <span className={"requirements"} >* requires white/color Philips Hue and HueBridge V2</span>
                            <img className={"hue_logo"} src={Hue}/>
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default SmartLights;