import { FixtureLibraryProps } from "../../interfaces/Interface";
import "./styles.scss";
import DmxMonitoringImg from "../../assets/screenshots/dmx_monitoring.png";

const FixtureLibrary: React.FC<FixtureLibraryProps> = () => {
    return (
        <section className="FixtureLibrary-container">
            <div className="FixtureLibrary-container_center">
                <div className="library">
                    <div className="library_description">
                        <h6 className={`library_description_subtitle`} >DMX LIGHTS IN YOUR IPAD</h6>
                        <h1 className={`library_description_title`} >DMX fixture library</h1>
                        <p className={`library_description_text`} >
                            Access hundreds of fixtures in our cloud library or create your own with our integrated factory.
                            Then, use Art-Net or sACN to control real time DMX output (you need DMX interface, for instance ENTTEC ODE).<br /><br />

                            A DMX monitor console is integrated into Photon. You can visualize in one click what it is sending to your network.
                        </p>
                        <div className={'library_description_label'} >
                            American DJ
                        </div>
                        <div className={'library_description_label'} >
                            Martin
                        </div>
                        <div className={'library_description_label'} >
                            Showtec
                        </div>
                        <div className={'library_description_label'} >
                            Arri
                        </div>
                        <div className={'library_description_label'} >
                            Chauvet
                        </div>
                        <div className={'library_description_label'} >
                            Starway
                        </div>
                        <div className={'library_description_label'} >
                            Cameo
                        </div>
                        <div className={'library_description_labelmore'} >
                            And more ...
                        </div>
                    </div>
                    <div className="library_img">
                        <div className="ipad">
                            <img src={DmxMonitoringImg} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default FixtureLibrary;