import { ControlLightsProps } from "../../interfaces/Interface";
import "./styles.scss";
import GroupImg from "../../assets/GroupImg.png";
import PresetImg from "../../assets/PresetImg.png";
import SequenceImg from "../../assets/SequenceImg.png";
import { useIntersection } from 'use-intersection';
import { useRef } from "react";
 
const ControlLights: React.FC<ControlLightsProps> = () => {
    const textAnimation = useRef(null);
    const isTextAnimation = useIntersection(textAnimation, {threshold: 1, once: true});

    return ( 
        <section className="ControlLights-container">
            <h6 className={`ControlLights-container_subtitle ${isTextAnimation ? "animate" : ""}`} ref={textAnimation}>BRING YOUR LIGHTS TO LIFE</h6>
            <h1 className={`ControlLights-container_title ${isTextAnimation ? "animate" : ""}`}>Controlling lights is easy</h1>
            <p className={`ControlLights-container_text ${isTextAnimation ? "animate" : ""}`}>
                Photon packs everything you love in a simple interface to control all your DMX and Philips Hue lights. <br />
                Group your devices by manufacturer, position or effect and play with them with a single touch.
            </p>
            <ul className="ControlLights-container_list">
                <li className="ControlLights-container_list__item">
                    <div className="ControlLights-container_list__item___card --group">
                        <img src={GroupImg} alt=""/>
                        <h4>Group</h4>
                    </div>
                    <h5>Gather devices in groups to control them together</h5>
                </li>
                <li className="ControlLights-container_list__item">
                    <div className="ControlLights-container_list__item___card --preset">
                        <img src={PresetImg} alt=""/>
                        <h4>Preset</h4>
                    </div>
                    <h5>Apply a preconfigured state to groups (select properties on the preset)</h5>
                </li>
                <li className="ControlLights-container_list__item">
                    <div className="ControlLights-container_list__item___card --sequence">
                        <img src={SequenceImg} alt=""/>
                        <h4>Sequence</h4>
                    </div>
                    <h5>Chain presets with transitions to create an animated sequence</h5>
                </li>
            </ul>
        </section>
     );
}
 
export default ControlLights;