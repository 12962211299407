import "./styles.scss";
import React, {useCallback, useMemo, useState} from "react";
import { useForm, ValidationError } from '@formspree/react';
import {ContactProps} from "../../interfaces/Interface";
import {GoogleReCaptcha, useGoogleReCaptcha} from "react-google-recaptcha-v3";

const ContactForm: React.FC<ContactProps> = () => {
    const [state, handleSubmit] = useForm("mnqlanwn");

    const [tokenState,setTokenState] = useState("")

    const handleVerify = useCallback((token) => {
        console.log(token);
        setTokenState(token);
    }, []);

    if (state.succeeded) {
        return (
            <section className="Contact-container">
                <section className="Contact-container-center">
                    <h1 className="Contact-container-center_title">Contact us</h1>

                    <section className="Contact-container-center_form">
                        <p>Please note that support requests, pre-sales questions, or compatibility questions sent through this form will NOT be answered. If you have a support question, please visit our <a target="_blank" href={"https://community.photon-ios.com"}>community</a> and open a thread.</p>

                        <div className={"success"} >Message sent successfully !</div>
                    </section>
                </section>
            </section>
        );
    }

    return (
        <section className="Contact-container">
            <section className="Contact-container-center">
                <h1 className="Contact-container-center_title">Contact us</h1>

                <section className="Contact-container-center_form">
                    <p>Please note that support requests, pre-sales questions, or compatibility questions sent through this form will NOT be answered. If you have a support question, please visit our <a href={"https://community.photon-ios.com"}>help center</a> and open a ticket.</p>

                    <form className={"form"} onSubmit={handleSubmit} >
                        <div className={"left"} >
                            <input id="name" name="name" placeholder={"Your name"} type={"text"} required/><br />
                            <ValidationError
                                prefix="Name"
                                field="name"
                                errors={state.errors}
                            />

                            <input id="email" name="email" placeholder={"Your e-mail"} type={"email"} required/><br />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                        </div>
                        <div className={"right"} >
                            <textarea id="message" name="message"  placeholder={"Your message"} required></textarea><br />
                            <ValidationError
                                prefix="Message"
                                field="message"
                                errors={state.errors}
                            />

                            <GoogleReCaptcha
                                onVerify={handleVerify}
                            />
                            <input type={"hidden"} name={"g-recaptcha-response"} id={"g-recaptcha-response"} value={tokenState} />

                            <button type="submit" disabled={state.submitting}>
                                Send your message
                            </button>
                        </div>
                    </form>
                </section>
            </section>
        </section>
    );
}
 
export default ContactForm;