import { CustomizeDashboardProps } from "../../interfaces/Interface";
import "./styles.scss";
import DashboardImg from "../../assets/screenshots/0.png";
import { useIntersection } from 'use-intersection';
import { useRef } from "react";
 
const CustomizeDashboard: React.FC<CustomizeDashboardProps> = () => {
    const textAnimation = useRef(null);
    const isTextAnimation = useIntersection(textAnimation, {threshold: 1, once: true});

    return ( 
        <section className="CustomizeDashboard-container">
            <h6 className={`CustomizeDashboard-container_subtitle ${isTextAnimation ? "animate" : ""}`} ref={textAnimation} >MOVE THINGS UP A GEAR</h6>
            <h1 className={`CustomizeDashboard-container_title ${isTextAnimation ? "animate" : ""}`} >Customize your dashboard</h1>
            <p className={`CustomizeDashboard-container_text ${isTextAnimation ? "animate" : ""}`} >
                Photon give you the possibility to create a fully customized dashboard. <br/>
                Create buttons, faders or titles and organize them as you wish. <br />
                Launch several presets or sequences at the same time with the dashboard.
            </p>
            <div className="CustomizeDashboard-container_dashboard">
                <div className="ipad">
                    <img src={DashboardImg} alt=""/>
                </div>
            </div>
            <ul className="CustomizeDashboard-container_list">  
                <li className="item">
                    <div className="item_icon">
                        <i className="bi bi-play-fill"></i>
                    </div>
                    <h4>Presets/sequences</h4>
                    <h5>Link presets and sequences to buttons and sliders to play it in a single touch</h5>
                </li>
                <li className="item">
                    <div className="item_icon">
                        <i className="bi bi-bar-chart-fill"></i>
                    </div>
                    <h4>Transitions</h4>
                    <h5>Add transitions (linear, expo, log) when you toggle a button. Transitions can be synchronized with the beat tracker</h5>
                </li>
                <li className="item">
                    <div className="item_icon">
                        <i className="bi bi-arrow-clockwise"></i>
                    </div>
                    <h4>Trigger mode</h4>
                    <h5>Set the button behavior when it is pressed : toggle, flash, continously and on release</h5>
                </li>
            </ul>
        </section>
     );
}
 
export default CustomizeDashboard;