import { FooterProps } from "../../interfaces/Interface";
import "./styles.scss";

 
const Footer: React.FC<FooterProps> = () => {
    return ( 
        <section className="Footer-container">
            <p className="Footer-container_rights">Copyright 2022 Photon. All rights reserved.</p>
            <div className="Footer-container_others">
                <a href="https://community.photon-ios.com/tos">Terms {"&"} conditions</a>
                <a href="/privacy">Privacy Policy</a>
            </div>
        </section>
     );
}
 
export default Footer;
