import { LandingProps } from "../../interfaces/Interface";
import "./styles.scss";
import appleBtnImage from "../../assets/apple_btn.png";
import firstIpad from "../../assets/screenshots/1.jpeg";
import secondIpad from "../../assets/screenshots/2.jpeg";
import myVideo from "../../assets/photon-landscape-flou.mp4";
import myVideoPoster from "../../assets/photon-landscape-flou.jpeg";
 
const Landing: React.FC<LandingProps> = () => {
    return ( 
        <section className="Landing-container">
            <video playsInline autoPlay loop muted className="Landing-container-center_video" poster={myVideoPoster}>
                <source src={myVideo} type="video/mp4"></source>
            </video>
            <section className="Landing-container-center">
                <h1 className="Landing-container-center_title">It's showtime <br></br> right at your fingertips</h1>
                <div className={"Landing-container-center_new"} >
                    <b>NEW</b> : MIDI EXTERNAL TRIGGERS
                </div>
                <a href="https://apps.apple.com/us/app/photon-2-dmx-light-controller/id1545017671" className="Landing-container-center_btnContainer">
                    <div className="btn">
                        <img src={appleBtnImage} alt=""/>
                    </div>
                </a>
            </section>

            <section className="Landing-container-iPads">
                <div className="left parallax" >
                    <div className="ipad" >
                        <img src={firstIpad} alt=""/>
                    </div>
                </div>
                <div className="right" >
                    <div>
                        <div className="ipad" >
                            <img src={secondIpad} alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </section>
     );
}
 
export default Landing;
