import { HardwareProps } from "../../interfaces/Interface";
import "./styles.scss";
import {
    Modal,
    useModal,
    ModalTransition,
} from 'react-simple-hook-modal';

import DmxKing from "../../assets/dmx_king.png"
import Enttec from "../../assets/enttec.png"
import Ode2 from "../../assets/ode2.png"

import { FaEthernet, FaWifi } from "react-icons/fa";
 
const Hardware: React.FC<HardwareProps> = () => {
    const { isModalOpen, openModal, closeModal } = useModal();

    return (
        <section className="Hardware-container">
            <div className="Hardware-container_center">
                <div className="interface">
                    <div className="interface_img">
                        <img src={DmxKing} alt=""/>
                        <div className={"interface_img_compatible"} >
                            <div className={"interface_img_compatible_dmx"} >
                                DMX COMPATIBLE
                            </div>
                            <div className={"interface_img_compatible_wifi"} >
                                <FaWifi/>
                                <span className={"label"} >Wifi</span>
                            </div>
                            <div className={"interface_img_compatible_ethernet"} >
                                <FaEthernet/>
                                <span className={"label"} >Ethernet</span>
                            </div>
                        </div>
                    </div>
                    <div className="interface_description">
                        <h2>Which hardware should I use ?</h2>
                        <p className="text" >
                            In order to wirelessly control your lights, Photon requires the use of an Ethernet (or WIFI) to DMX adapter.
                            Photon uses Art-Net or sACN, the leading open industry standards.<br /><br />

                            The iPad must then be connected via <b>Wifi</b> or <b>Ethernet</b> (Ethernet need adapter for iPad) to your network.
                        </p>
                        <button onClick={openModal} className="button">
                            {"Learn More"} <i className="bi bi-chevron-right"></i>
                            <Modal
                                id="hardware-modal"
                                isOpen={isModalOpen}
                                transition={ModalTransition.BOTTOM_UP}
                                modalClassName={"modal"}
                            >
                                <button className={"close"} onClick={closeModal}><i className="bi bi-x-circle-fill"></i></button>
                                <h2>Tested adapters</h2>
                                <div className={"item"} >
                                    <div className={"image"} >
                                        <img src={DmxKing} alt=""/>
                                    </div>
                                    <div className={"text"} >
                                        <h3>DmxKing</h3>
                                        <h2>eDMX1 PRO</h2>
                                        <p>129 $</p>
                                    </div>

                                    <a target="_blank" href="https://dmxking.com/artnetsacn/edmx1-pro" className="button">
                                        {"Buy"} <i className="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                                <div className={"item"} >
                                    <div className={"image"} >
                                        <img src={Enttec} alt=""/>
                                    </div>
                                    <div className={"text"} >
                                        <h3>ENTTEC</h3>
                                        <h2>ODE MK2</h2>
                                        <p>199 $</p>
                                    </div>

                                    <a target="_blank" href="https://www.enttec.co.uk/en/product/controls/dmx-ethernet-lighting-control/ethernet-dmx-interface/" className="button">
                                        {"Buy"} <i className="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </Modal>
                        </button>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Hardware;